<template>
<div>
  <CRow>
    <CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
             <CRow>
                <CCol col="6" class="text-left">
                    <h4>Detail Wallet Group</h4>
                </CCol>
                <CCol col="6" class="text-right">
                      <b-button-group>
                         <b-button variant="warning" v-on:click="actionEdit()" v-if="seenBtnWarn">Edit</b-button>
                         <b-button v-on:click="actionCancel()" v-if="seenBtnCcel">Cancel</b-button>
                         <b-button variant="success" v-on:click="actionUpdate()" v-if="seenBtnUpdate">Update</b-button>
                      </b-button-group>
                </CCol>
             </CRow>
          </CCardHeader>
          <CCardBody>
             <CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol>
             </CRow>

             <b-form>

               <CInput
                type="text"
                label="Unique Number"
                placeholder="Type Unique Number"
                :disabled="!isDisabledUN"
                v-model="groupUniqueNumber"
                horizontal
               />

               <CInput
                type="text"
                label="Group Name"
                placeholder="Type Group Name"
                :disabled="!isDisabledGN"
                v-model="name"
                horizontal
               />

                <!-- <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Features:</label>
                  </b-col>
                  <b-col sm="9">
                    <multiselect @select="toggleFeatureSelected" @remove="toggleFeatureUnSelect" :disabled="!isDisabledFeat" v-model="features" :options="optfeatures" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                    <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>
                  </b-col>
                </b-row><br/> -->

                 <div class="text-center">
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                 </div>

                <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Features:</label>
                  </b-col>
                  <b-col sm="5">
                      <div v-for="(feature, index) in optfeatures">
                        <span v-if=" index <= div">
                        <label><input type="checkbox" :disabled="!isDisabledFeat"  style="width: 20px; height: 20px;" v-model="feature.selected"> {{ feature.name }}</label>
                        </span>
                      </div>
                  </b-col>
                   <b-col sm="4">
                      <div v-for="(feature, index) in optfeatures">
                        <span v-if=" index > div">
                        <label><input type="checkbox" :disabled="!isDisabledFeat"  style="width: 20px; height: 20px;" v-model="feature.selected"> {{ feature.name }}</label>
                        </span>
                      </div>
                   </b-col>
                </b-row><br/>

                <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Follow User Group</label>
                  </b-col>
                  <b-col sm="9">
                     <b-row>
                     <b-col sm="3">
                     <input type="radio" style="width: 20px; height: 20px;" :disabled="!isDisabledfug"  v-model="follusergroup" value="true"> Yes
                     </b-col>
                     <b-col sm="3">
                     <input type="radio" style="width: 20px; height: 20px;" :disabled="!isDisabledfug" v-model="follusergroup" value="false"> No
                     </b-col>
                     <b-col sm="3"></b-col>
                     </b-row>
                   </b-col>
                  </b-col>
                </b-row><br/>

                <b-row v-if="seenBtnAddLim">
                    <b-col sm="3">
                       <label for="input-small" >Country Code</label>
                    </b-col>
                    <b-col sm="3">
                           <vue-typeahead-bootstrap v-model="countryCode" :disabled="!isDisabledCC" :data="optcCode">
                           </vue-typeahead-bootstrap>
                           <label style="font-size:13px;color:grey">*e.g. SG, ID, *</label>
                    </b-col>

                    <b-col sm="2">
                       <label for="input-small" >Currency</label>
                    </b-col>
                    <b-col sm="4">
                       <b-form-select v-model="currency" :options="optCurrency"></b-form-select>
                    </b-col>

                </b-row><br/>

                <b-row v-if="seenAddTheme">
                    <b-col sm="3"></b-col>
                    <b-col sm="9">
                         <b-button-group>
                           <b-button  v-on:click="AddLimit()" variant="primary">Add Limit</b-button>
                           <b-button  v-on:click="RemoveLimit()" variant="danger">Remove Limit</b-button>
                           <!-- <b-button  v-on:click="AddTheme()" variant="primary">Add Theme</b-button>
                           <b-button  v-on:click="RemoveTheme()" variant="danger">Remove Theme</b-button>                          -->
                         </b-button-group>
                    </b-col>
                </b-row><br/>

                <!-- <label>codes :</label> {{ codes }} <br/>
                <label>map :</label> {{ map }} <br/>
                <label>tabs :</label> {{ tabs }} -->

                <h5>Limits</h5>
                <b-tabs content-class="mt-3">
                    <div v-for="(value, key, index) in tabs">
                      <b-tab :title="value" >

                      <div class="accordion" role="tablist">
                      <div v-for="(value2, key2, index2) in acdlist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle="'accordion' + key2" variant="info">{{ value2.title }}</b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion' + key2" visible accordion="my-accordion" role="tabpanel">
                              <b-card-body>

                                <span v-if="value2.key == 'firstTransferLimit'">
                                    <CInput
                                        type="number" min=0
                                        :disabled="!isDisabledftl"
                                        :name="value +'|'+value2.key"
                                        v-model="form[value +'|'+value2.key]"
                                        :label="value2.title" :placeholder="'Type ' + value2.title"
                                        horizontal
                                      />
                                </span>

                                <span v-else>
                                  <div v-for="(value3, key3, index3) in fieldlist">

                                      <span v-if="(value2.abbr === 'p2m' || value2.abbr === 'rcl')
                                            && value3.key !== 'dailyReceiveLimit'
                                            && value3.key !== 'monthlyReceiveLimit'
                                            && value3.key !== 'annualReceiveLimit'
                                            && value3.key !== 'transactionReceiveLimit'
                                            && value3.key !== 'transactionReceiveMinLimit'
                                            && value3.key !== 'maxBalance'">

                                           <span v-if="(value3.key === 'transactionSendMinLimit' || value3.key === 'transactionReceiveMinLimit') ">
                                               <CInput type="number"
                                                :name="value +'|'+value2.key +'|'+ value3.key"
                                                v-model="form[value +'|'+value2.key +'|'+value3.key]"
                                                :label="value3.label" :placeholder="'Type ' + value3.label"
                                                description="If No Minimum, Enter -1" :disabled="!isDisabledfield" horizontal />
                                           </span>
                                           <span v-else >
                                                <CInput type="number" min=0
                                                :name="value +'|'+value2.key +'|'+ value3.key"
                                                v-model="form[value +'|'+value2.key +'|'+value3.key]"
                                                :label="value3.label" :placeholder="'Type ' + value3.label" :disabled="!isDisabledfield" horizontal />
                                           </span>

                                      </span>
                                      <span v-else-if="(value2.abbr === 'tucl')
                                            && value3.key !== 'dailySendLimit'
                                            && value3.key !== 'monthlySendLimit'
                                            && value3.key !== 'annualSendLimit'
                                            && value3.key !== 'transactionSendLimit'
                                            && value3.key !== 'transactionSendMinLimit' ">

                                           <span v-if="(value3.key === 'transactionSendMinLimit' || value3.key === 'transactionReceiveMinLimit') ">
                                               <CInput type="number"
                                                :name="value +'|'+value2.key +'|'+ value3.key"
                                                v-model="form[value +'|'+value2.key +'|'+value3.key]"
                                                :label="value3.label" :placeholder="'Type ' + value3.label"
                                                description="If No Minimum, Enter -1" :disabled="!isDisabledfield" horizontal />
                                           </span>
                                           <span v-else >
                                                <CInput type="number" min=0
                                                :name="value +'|'+value2.key +'|'+ value3.key"
                                                v-model="form[value +'|'+value2.key +'|'+value3.key]"
                                                :label="value3.label" :placeholder="'Type ' + value3.label" :disabled="!isDisabledfield" horizontal />
                                           </span>

                                      </span>
                                      <span v-else-if="(value2.abbr !== 'p2m' && value2.abbr !== 'rcl' && value2.abbr !== 'tucl')
                                                        && value3.key !== 'maxBalance'">

                                           <span v-if="(value3.key === 'transactionSendMinLimit' || value3.key === 'transactionReceiveMinLimit') ">
                                               <CInput type="number"
                                                :name="value +'|'+value2.key +'|'+ value3.key"
                                                v-model="form[value +'|'+value2.key +'|'+value3.key]"
                                                :label="value3.label" :placeholder="'Type ' + value3.label"
                                                description="If No Minimum, Enter -1" :disabled="!isDisabledfield" horizontal />
                                           </span>
                                           <span v-else >
                                                <CInput type="number" min=0
                                                :name="value +'|'+value2.key +'|'+ value3.key"
                                                v-model="form[value +'|'+value2.key +'|'+value3.key]"
                                                :label="value3.label" :placeholder="'Type ' + value3.label" :disabled="!isDisabledfield" horizontal />
                                           </span>

                                      </span>

                                  </div>
                                </span>


                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>

                      </b-tab>
                    </div>
                </b-tabs>

                 <!-- <h5>Themes</h5>

                 <b-tabs content-class="mt-3">
                    <div v-for="(value3, key3, index3) in themes">
                      <b-tab :title="value3" >

                        <div v-for="(value4, key4, index4) in fieldtheme">
                             <b-row>
                              <b-col sm="3">
                                 <label for="input-small" >{{ value4.label }}</label>
                              </b-col>
                              <b-col sm="9">
                                  <span v-if="value4.type == 'color'">
                                     <v-input-colorpicker :disabled="!isDisabledth1" v-model="form2[value4.abbr+value3]" />
                                  </span>
                                  <span v-else>
                                     <CInput
                                        :disabled="!isDisabledth2"
                                        v-model="form2[value4.abbr+value3]"
                                        :placeholder="'Input ' + value4.label"
                                      />
                                  </span>
                               </b-col>
                            </b-row>
                        </div>

                      </b-tab>
                    </div>
                 </b-tabs><br/> -->

            </b-form>

          </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js';
 import Multiselect from 'vue-multiselect';
 import InputColorPicker from 'vue-native-color-picker';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import CPhone from '@/assets/phone.json';
import { conditionalExpression } from '@babel/types';

export default {
    name: 'detailusergroup',
    components: {
      VueTypeaheadBootstrap,  Multiselect,
      'v-input-colorpicker': InputColorPicker
    },
    data() {
      return {
          spinner : false,
          div:0,
          seenAddTheme:false,
          seenBtnCcel: false,
          seenBtnWarn: true,
          seenBtnUpdate:false,
          seenBtnAddLim:false,
          isDisabledftl:false,
          isDisabledfield:false,
          isDisabledUN:false,
          isDisabledGN:false,
          isDisabledFeat:false,
          isDisabledCC:false,
          isDisabledth1:false,
          isDisabledth2:false,
          isDisabledfug:false,
          initfeatures:[],
          groupUniqueNumber:'',
          name:'',
          follusergroup:'false',
          features:[],
          optfeatures:[],
          optCurrency:[],
          currency:'',
          optcCode:[],
          shadwdata: [],
          countryCode:'',
          tabs:[],
          codes:[],
          map:[],
          themes:[],
          numtheme:0,
          form: [],
          form2:[],
          acdlist:[
             { abbr : 'dcl', key: 'defaultCurrencyLimits', title: 'Default Currency Limit'},
             { abbr : 'tucl', key: 'topupCurrencyLimits', title: 'Topup Currency Limit'},
             { abbr : 'p2p', key: 'p2pCurrencyLimits', title: 'P2P Currency Limit'},
             { abbr : 'p2m', key: 'p2mCurrencyLimits', title: 'P2M Currency Limit'},
             { abbr : 'rcl', key: 'remittanceCurrencyLimits', title: 'Remittance Currency Limits'},
             { abbr : 'ftl', key: 'firstTransferLimit', title: 'First Transfer Limit'},
          ],
          fieldlist:[
             { abbr : 'dsl', key: 'dailySendLimit', label: 'Daily Send Limit'},
             { abbr : 'mb', key: 'maxBalance', label: 'Max. Balance'},
             { abbr : 'drl', key: 'dailyReceiveLimit', label: 'Daily Receive Limit'},
             { abbr : 'msl', key: 'monthlySendLimit', label: 'Monthly Send Limit'},
             { abbr : 'mrl', key: 'monthlyReceiveLimit', label: 'Monthly Receive Limit'},
             { abbr : 'asl', key: 'annualSendLimit', label: 'Annual Send Limit'},
             { abbr : 'arl', key: 'annualReceiveLimit', label: 'Annual Receive Limit'},
             { abbr : 'trsl', key: 'transactionSendLimit', label: 'Transaction Send Limit'},
             { abbr : 'trrl', key: 'transactionReceiveLimit', label: 'Transaction Receive Limit'},
             { abbr : 'trsml', key: 'transactionSendMinLimit', label: 'Transaction Send Min Limit'},
             { abbr : 'trrml', key: 'transactionReceiveMinLimit', label: 'Transaction Receive Min Limit'},
          ],
          fieldtheme:[
              { abbr : 'nameTh', type : 'text', key: 'nameTheme', label: 'Theme Name'},
              { abbr : 'logoUrl',  type : 'text', key: 'logoUrl', label: 'Logo Url'},
              { abbr : 'dcolr', type : 'color', key: 'color', label: 'Default Color'},
              { abbr : 'acolr', type : 'color', key: 'accentColor', label: 'Accent Color'},
              { abbr : 'btcolr', type : 'color', key: 'buttonTextColor', label: 'Button Text Color'},
              { abbr : 'prcolr', type : 'color', key: 'primaryColor', label: 'Primary Color'},
          ],
          count:1,
          seen:false,
          msg :'',
          color:''
        };
    },
    created () {
        this.spinner = true;
        this.getWalletGroup(this.$route.params.id);
        setTimeout( () => this.getConfigList() , 2000);
    },
    methods: {

      AddTheme(){
            var num = this.numtheme;
            this.numtheme = num + 1;
            this.themes.push(this.numtheme.toString());
      },

      RemoveTheme(){
         var num   = this.numtheme.toString();
         let temp  = this.themes;
         let index = temp.findIndex( temp => temp === num);
         if (index > -1) {  temp.splice(index, 1); }
         this.numtheme = num-1;

         this.form2 = [];
      },

      actionEdit(){

         this.seenBtnCcel=true;
         this.seenBtnWarn=false;
         this.seenBtnUpdate=true;
         this.seenBtnAddLim=true;
         this.seenAddTheme=true;

         this.isDisabledftl=true;
         this.isDisabledfield=true;
         this.isDisabledGN=true;
         this.isDisabledFeat=true;
         this.isDisabledCC=true;
         this.isDisabledth1=true;
         this.isDisabledth2=true;
         this.isDisabledfug=true;

      },

      actionCancel(){

         this.seenBtnCcel=false;
         this.seenBtnWarn=true;
         this.seenBtnUpdate=false;
         this.seenBtnAddLim=false;
         this.seenAddTheme=false;

         this.isDisabledftl=false;
         this.isDisabledfield=false;
         this.isDisabledGN=false;
         this.isDisabledFeat=false;
         this.isDisabledCC=false;
         this.isDisabledth1=false;
         this.isDisabledth2=false;
         this.isDisabledfug=false;
      },

      isEmptyObject(obj){

            return JSON.stringify(obj) === '{}';

      },

      getIndexShadwdata(code){

         let temp2   = this.shadwdata;
          for (var key in temp2) {
              var temp3 = temp2[key];
                for (var key2 in temp3) {
                    if(key2===code){ return key; }
                }
          }

      },

      actionUpdate(){

            var formdata2 = this.form2;  var temp = []; var themes = {}; let headers = {};
            var themefield= ['accentColor','buttonTextColor','primaryColor']; var validate = [];
            var theme = {}; var body = {}; var params2 = {}; var nametheme = ''; var logoUrl = '';
            var j = this.numtheme;
            if(j>=1){
                for (j ; j >= 1; j--) {
                   for (var key2 in this.fieldtheme) {
                       var abbr      = this.fieldtheme[key2].abbr; var fieldname = abbr+j;
                       var keyname   = this.fieldtheme[key2].key;
                       var value     = formdata2[fieldname];
                       if(themefield.includes(keyname)){ theme[keyname] = !value ? '#0f0000' : value;
                       } else if(keyname==="color"){ body[keyname] = !value ? '#0f0000' : value;
                       } else if(keyname==="logoUrl"){ logoUrl = value; body[keyname] = value;
                       } else {  nametheme = value; }
                   }

                   if(!nametheme || !logoUrl){ validate.push(false); }
                   body['theme'] = theme;
                   params2[nametheme] = body;
                }

                headers['walletTheme'] = params2;
            }

            var formdata = this.form;
            let params   = {};
            var i=0;

            if(this.codes.length > 0){
                for (var key in formdata) {
                    var val    = parseInt(formdata[key]);
                    var value  = Number.isNaN(val) ? 0 : val;
                    var fields = key.split("|");
                    i=0;
                    if(value!==0){
                        for (var key2 in fields) {  
                            /* i value should be reset each loop */
                            if(i==0){
                                var code = fields[key2];
                                var idx  = this.getIndexShadwdata(code); 
                                //console.log(code +"|"+ idx);
                            } else if(i==1){
                                var curr = fields[key2];
                            } else if(i==2){
                                var tab = fields[key2];
                                if(tab=="firstTransferLimit"){
                                    var par   = this.shadwdata[idx][code][tab][curr];
                                    var str   = JSON.stringify(par);
                                    let res   = JSON.parse(str);
                                    res["maxTransferAmount"] = value;
                                    this.shadwdata[idx][code][tab][curr] = res;
                                }
                            } else if(i==3){
                                var lim = fields[key2];
                                //console.log(idx+'|'+code+'|transactionLimits|'+tab+'|'+curr+'|'+lim+'=='+value);                               
                                var par   = this.shadwdata[idx][code]['transactionLimits'][tab][curr];
                                var str   = JSON.stringify(par);
                                var fstr  = lim+"\":"+0; var sstr = lim+"\":"+value;
                                var strreplace  = str.replace(fstr,sstr);
                                let res  = JSON.parse(strreplace);
                                this.shadwdata[idx][code]['transactionLimits'][tab][curr] = res;
                            }
                        i++;
                        }
                    }
                }
            }

            for (var key3 in this.shadwdata) {
                var subs = this.shadwdata[key3];
                    for (var key4 in subs) {
                      var fkey = subs[key4].countryCode;
                      params[fkey] = subs[key4];
                    }
            }

            let result   = params;
            var list     = this.optfeatures;
            var features = [];

            for (var key in list) {
              if(list[key].selected){
                  var feature = list[key].name; features.push(feature);
              }
            }

            var walletGroupId   = this.groupUniqueNumber;
            var follusergroup   = this.follusergroup;
            headers['name']     = this.name;
            headers['features'] = features;
            headers['limits']   = result;

            for (var key in headers) {
              if(!headers[key]) { validate.push(false); } else { validate.push(true); }
            }

            if(!validate.includes(false)){
                this.updateWalletGroup(walletGroupId,follusergroup,headers);
            } else {
               this.msg   = 'Data Not Complete, All Data Required!';
               this.color = 'warning'; this.seen  = true;
               setTimeout( () => this.seen=false , 5000);
            }

      },

      updateWalletGroup: function(walletGroupId,follusergroup,params) {
          AuthService.updateWalletGroup(walletGroupId,follusergroup,params).then(resp => {   //console.log(resp);

               if(resp.message==="Success"){
                  this.tabs = []; this.themes = [];  this.shadwdata = [];
                  this.getWalletGroup(this.$route.params.id);
                  this.actionCancel();
                  this.msg   = "Update Wallet Group Success!";
                  this.color = 'success'; this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000);
               } else {
                  this.msg   = "Update Wallet Group Failed!";
                  this.color = 'danger'; this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000);
               }

           }, error => {
            console.log('error');
          });
      },

      updateUserGroup: function(params) {
          TransactService.updateUserGroup(params).then(resp => {   //console.log(resp);

               if(resp.message==="Success"){
                  this.tabs = []; this.themes = [];  this.shadwdata = [];
                  this.getSingleUserGroup(this.$route.params.id);
                  this.actionCancel();
                  this.msg   = "Update User Group Success!";
                  this.color = 'success'; this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000);
               } else {
                  this.msg   = "Update User Group Failed!";
                  this.color = 'danger'; this.seen  = true;
                  setTimeout( () =>  this.seen=false , 5000);
               }

           }, error => {
            console.log('error');
          });
      },

      AddLimit(){
            var key  = this.countryCode;
            var curr = this.currency;
            var code = (key === "*") ? "*" : CPhone[key];
            var init = code+"|"+curr;  let currlist = {};

            if(key && curr){
                if(!this.codes.includes(code) && code) {
                    this.codes.push(code);
                    currlist[code] = [curr];  this.map.push(currlist);
                    var listcurr = [curr]
                    this.setSingleParams(init,code,listcurr);                    
                } else {
                    /*-- update map section-- */
                    var tempo = this.map;
                    for (var key3 in tempo) {
                        var temp5 = tempo[key3];
                        for (var key4 in temp5) {
                           if(key4===code){
                               if(!tempo[key3][code].includes(curr)) {
                                  tempo[key3][code].push(curr);
                               }
                           }
                        }
                    }
                    /*--end of update map section-- */
                    var tempor = this.shadwdata;
                    for (var key5 in tempor) {
                        var tempora = tempor[key5];
                        for (var key6 in tempora) {
                            if(key6===code){
                                // Delete the previous shadow key 
                                delete this.shadwdata[key5];                                
                                var listcurr = tempo[key3][code];
                                // Re-generate shadow after add new one
                                this.setSingleParams(init,code,listcurr);
                            }
                        }
                    }
                }
            } else {
               this.msg   = 'Country Code and Currency are Required!';
               this.color = 'warning'; this.seen  = true;
               setTimeout( () =>  this.seen=false , 5000);
            }
            
      },

      RemoveLimit(){          
          var key   = this.countryCode;
          var curr  = this.currency;
          var code  = (key === "*") ? "*" : CPhone[key];
          var init  = code+"|"+curr;          
          
          if(key && curr){
                /*-------delete tabs------*/
                let temp  = this.tabs;
                var idx   = temp.findIndex( temp => temp === init);            
                if(idx !== -1 ) { temp.splice(idx, 1); }

                /*-------delete map------*/
                var tempo = this.map;
                for (var key3 in tempo) {
                        var temp5 = tempo[key3];
                        for (var key4 in temp5) { 
                            if(key4 == code){
                                var listcurr = temp5[key4];
                                temp5[key4] = [];
                                listcurr.forEach(function(cr) {
                                    if(cr !== curr){
                                        temp5[key4].push(cr);
                                    }
                                });
                                if(temp5[key4].length == 0){
                                    delete temp5[key4]; 
                                    /*-------delete codes------*/                                   
                                    var temp2 = this.codes;                                    
                                    var idx2  = temp2.findIndex( temp2 => temp2 === code);
                                    if(idx2 !== -1 ) { temp2.splice(idx2, 1); } 
                                }
                            }                            
                        }
                }               

                /*---delete key from the form---*/
                var formdata = this.form;
                for (var key in formdata) {                    
                    var field   = key.split("|"); 
                    var formkey = field[0]+"|"+field[1];
                    if(formkey == init){
                        delete this.form[key];
                    }
                }
                /*--- reset shadow form ---*/
                this.shadwdata.length=0;
                /*--- regenerate shadow form ---*/
                var tempo = this.map;                
                for (var key3 in tempo) {
                    var temp5 = tempo[key3];
                    for (var key4 in temp5) {
                        var code     = key4;
                        var listcurr = temp5[key4];
                        this.setMultiParams(code,listcurr);
                    }
                }
                //console.log(this.form);
                //console.log(this.shadwdata);
           } else {
               this.msg   = 'Country Code and Currency are Required!';
               this.color = 'warning'; this.seen  = true;
               setTimeout( () => this.seen=false , 5000);
           }
      },

      toggleFeatureSelected(){
      },

      toggleFeatureUnSelect(){
      },

      setLimit(code,currlist){

            let temp   = {};  let temp2 = {}; let temp3  = {}; let temp4 = {};
            let fields = {};  let field = {}; let result = {}; 

                var items = this.acdlist; var i = this.count;
                for (var key in items) {
                    var groupName = items[key].key;
                    if(groupName !== "firstTransferLimit"){
                        for (var key2 in this.fieldlist) {
                            var fieldName  = this.fieldlist[key2].key;
                            field[fieldName] = 0;  i++;
                        }

                        for (var key3 in currlist) {
                            var curr = currlist[key3];  temp2[curr] = field;
                        }

                        field = {};
                        fields[groupName] = temp2;  temp2 = {};
                    }
                }

                this.count=i;
                temp['countryCode']        = code;
                temp['transactionLimits']  = fields;
                temp3['maxTransferAmount'] = 0;

                for (var key3 in currlist) {
                   var curr = currlist[key3]; temp4[curr] = temp3;
                }

                temp['firstTransferLimit'] = temp4;
                result[code] = temp;
                this.shadwdata.push(result);

      },

      setSingleParams(init,code,currlist){

            if(!this.tabs.includes(init) && code) {

                this.tabs.push(init);  this.seenAddTheme=true;

                let temp   = {};  let temp2 = {}; let temp3  = {}; let temp4 = {};
                let fields = {};  let field = {}; let result = {};

                    var items = this.acdlist; var i = this.count;
                    for (var key in items) {
                          var groupName = items[key].key;
                          if(groupName !== "firstTransferLimit"){
                            for (var key2 in this.fieldlist) {
                                var fieldName    = this.fieldlist[key2].key;                                
                                field[fieldName] = 0;   i++;
                            }

                            for (var key3 in currlist) {
                               var curr = currlist[key3];  temp2[curr] = field;
                            }

                            field = {};
                            fields[groupName] = temp2;  temp2 = {};
                          }
                    }

                    this.count=i;
                    temp['countryCode']        = code;
                    temp['transactionLimits']  = fields;
                    temp3['maxTransferAmount'] = 0;

                    for (var key3 in currlist) {
                        var curr = currlist[key3]; temp4[curr] = temp3;
                    }

                    temp['firstTransferLimit'] = temp4;
                    result[code] = temp;
                    this.shadwdata.push(result);
            }

      },

      setMultiParams(code,currlist){

                let temp   = {};  let temp2 = {}; let temp3  = {}; let temp4 = {};
                let fields = {};  let field = {}; let result = {};

                    var items = this.acdlist; var i = this.count;
                    for (var key in items) {
                          var groupName = items[key].key;                          
                          if(groupName !== "firstTransferLimit"){
                            for (var key2 in this.fieldlist) {
                                var fieldName    = this.fieldlist[key2].key;                                
                                field[fieldName] = 0;
                                i++
                            }

                            for (var key3 in currlist) {
                               var curr = currlist[key3];  temp2[curr] = field;
                            }
                            
                            fields[groupName] = temp2; 
                            field = {}; temp2 = {};
                          }
                    }

                    this.count=i;
                    temp['countryCode']        = code;
                    temp['transactionLimits']  = fields;
                    temp3['maxTransferAmount'] = 0;

                    for (var key3 in currlist) {
                        var curr = currlist[key3]; temp4[curr] = temp3;
                    }

                    temp['firstTransferLimit'] = temp4;
                    result[code] = temp;
                    this.shadwdata.push(result);

      },

      getWalletGroup: function(code) {
          AuthService.getWalletGroup(code).then(resp => {

             this.groupUniqueNumber = resp.groupUniqueNumber;
             this.name              = resp.name;
             this.follusergroup     = resp.followUserGroup;
             this.features          = resp.features;
             var limits             = resp.limits;  //console.log(limits);

             var currlist = []; let initcurrlist = {};

             for (var key in limits) {
                    var lim  = limits[key];
                    var code = lim.countryCode;
                    if(!this.codes.includes(code)){
                        this.codes.push(code);
                    }

                    for (var key3 in this.acdlist) {
                        var groupname = this.acdlist[key3].key;
                        if(groupname!=="firstTransferLimit"){
                             var temp = lim.transactionLimits[groupname];
                             for (var key6 in temp) {
                                 for (var key4 in this.fieldlist) {
                                    var init = code+"|"+key6;
                                    
                                    if(!this.tabs.includes(init)) { this.tabs.push(init); }
                                    if(!currlist.includes(key6)) {                                         
                                        currlist.push(key6); 
                                    }

                                    var field = this.fieldlist[key4].key;
                                    var value = lim.transactionLimits[groupname][key6][field];
                                    //console.log(code+'|'+key6+'|'+groupname+'|'+field+'='+value);
                                    this.form[code+'|'+key6+'|'+groupname+'|'+field] = value;   value = 0; 
                                                                      
                                 }
                                 currlist = []; 
                             }
                        } else {
                            for (var key5 in lim.firstTransferLimit) {
                                var init = code+"|"+key5;
                                if(!this.tabs.includes(init)) { this.tabs.push(init); }
                                if(!currlist.includes(key5)) { currlist.push(key5); }

                                var value = lim.firstTransferLimit[key5]['maxTransferAmount'];
                                this.form[code+'|'+key5+'|'+groupname] = value;
                            }
                        }
                    }

                    this.setLimit(code,currlist);     
                    initcurrlist[code] = currlist;
                    if(this.map.length===0){ 
                        this.map.push(initcurrlist);
                        currlist=[];                                       
                    }                    
             }              
          }, error => {
            console.log('error');
          });
      },

      getSingleUserGroup: function(id) {
          TransactService.getSingleUserGroup(id).then(resp => {  //console.log(resp);

              this.groupUniqueNumber = resp.groupUniqueNumber;
              this.name    = resp.name;
              var limits   = resp.limits;

              var codelist = []; var currlist = []; let initcurrlist = {};

              for (var key in limits) {

                    var lim  = limits[key];
                    var code = lim.countryCode; this.codes.push(code);

                    for (var key3 in this.acdlist) {
                        var groupname = this.acdlist[key3].key;

                        if(groupname!=="firstTransferLimit"){
                             var temp = lim.transactionLimits[groupname];
                             for (var key6 in temp) {
                                 for (var key4 in this.fieldlist) {

                                    var init = code+"|"+key6;
                                    if(!this.tabs.includes(init)) { this.tabs.push(init); }
                                    if(!currlist.includes(key6)) { currlist.push(key6); }

                                    var field = this.fieldlist[key4].key;
                                    var value = lim.transactionLimits[groupname][key6][field];

                                    //console.log(code+'|'+key6+'|'+groupname+'|'+field+'='+value);

                                    this.form[code+'|'+key6+'|'+groupname+'|'+field] = value;   value = 0;
                                 }
                             }

                        } else {
                            for (var key5 in lim.firstTransferLimit) {
                                var init = code+"|"+key5;
                                if(!this.tabs.includes(init)) { this.tabs.push(init); }
                                if(!currlist.includes(key5)) { currlist.push(key5); }

                                var value   = lim.firstTransferLimit[key5]['maxTransferAmount'];
                                this.form[code+'|'+key5+'|'+groupname] = value;
                            }
                        }
                    }

                    this.setLimit(code,currlist);
                    if(this.map.length===0){
                        initcurrlist[code] = currlist;
                        this.map.push(initcurrlist);
                    }

              }

              //console.log(this.shadwdata);

              var walletTheme = resp.walletTheme;  //console.log(walletTheme);
              var count       = Object.keys(walletTheme).length;
              var themefield  = ['accentColor','buttonTextColor','primaryColor'];
              var mapfield    = { 'color':'dcolr',
                                  'logoUrl':'logoUrl',
                                  'logo':'logoUrl',
                                  'accentColor':'acolr',
                                  'buttonTextColor':'btcolr',
                                  'primaryColor':'prcolr' } ;

             if(walletTheme['color']){
                 var i = 1;
                 this.themes.push(i.toString()); this.numtheme = i;
                 var data = walletTheme;
                 this.form2['nameTh'+i] = 'None';
                 for (var key in data) {
                        var val  = data[key];
                        if(key=="color" || key=="logo") {
                            var kname = mapfield[key];  this.form2[kname+i] = val; }
                        if(key=="theme") {
                            for (var key3 in themefield) {
                                var field   = themefield[key3];
                                var keyname = mapfield[field];
                                this.form2[keyname+i] = val[field];
                            }
                        }

                 }

                 //console.log('multi false' + i);

             } else {
                  var j = 1;
                  for (var key in walletTheme) {
                        this.themes.push(j.toString());  this.numtheme = j;
                        this.form2['nameTh'+j] = key;
                        var data = walletTheme[key];
                        for (var key2 in data) {
                            var val   = data[key2];
                            if(key2=="color" || key2=="logoUrl") {
                                var kname = mapfield[key2];  this.form2[kname+j] = val; }
                            if(key2=="theme") {
                                for (var key3 in themefield) {
                                    var field   = themefield[key3];
                                    var keyname = mapfield[field];
                                    this.form2[keyname+j] = val[field];
                                }
                            }
                        }

                  j++; }

                  //console.log('multi true ' +j);

            }

            //console.log(this.numtheme);

          }, error => {
              console.log('error');
          });
      },

      sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {   //console.log(resp);

                var currSrc=[]; var temp = []; var temp2 = [];
                var arrcurrency = resp.currency;
                for (const item of arrcurrency){ temp.push(item); }
                temp.sort();
                for (var key in temp) {
                    var curr = temp[key];
                    currSrc.push({ value: curr , text: curr });
                }
                this.optCurrency = currSrc;

                var ccodeSrc =[]; var basephone = [];
                for (var key in CPhone) { ccodeSrc.push(key); }
                this.optcCode = ccodeSrc;

                var gFeatSrc=[]; var temp = []; var baseFeatures = [];
                var groupFeatures = resp.groupFeatures;
                for (var key in groupFeatures) {
                    var gFeat = groupFeatures[key];
                    var selected = this.features.includes(gFeat) ? true : false;
                    gFeatSrc.push({ name: gFeat,  selected : selected });
                }
                gFeatSrc.sort(this.sortByProperty("name"));
                this.div  = (Math.ceil(gFeatSrc.length / 2)) - 1;
                this.optfeatures = gFeatSrc;

                this.spinner = false;

                // var code = this.$route.params.id;
                // AuthService.getWalletGroup(code).then(resp2 => {
                //     for (var key in resp2.features) {
                //       var feature = resp2.features[key];  //console.log(feature);
                //       let index   = gFeatSrc.findIndex(gFeatSrc => gFeatSrc.name === feature);
                //       this.features.push(this.optfeatures[index]);
                //     }
                // });

           }, error => {
              console.log('error');
          });
      },

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>